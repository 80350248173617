<template>
  <div>
    <master-component
      :config="config"
      :showRemove="false"
    ></master-component>
  </div>
</template>


<script>
import { getLocations } from "../js/dropdown-service";
import { UserRole, InputType, DialogSize } from "./../js/constants";

export default {
  data() {
    return {
      config: {},
    };
  },
  created() {
    this.initialConfig();
  },
  methods: {
    initialConfig() {
      this.config = {
        name: "master-teacher",
        shortName: "อาจารย์ที่ปรึกษา",
        search: this.searchSection,
        form: this.formSection,
        table: this.tableSection,
        permission: this.permission,
        url: this.urlSection,
      };
    },
  },

  computed: {
    urlSection() {
      let searchUrl = "/teacher/search";
      let getUrl = "/teacher";
      let addUrl = "/teacher";
      let editUrl = "/teacher";
      let removeUrl = "/teacher";

      return {
        search: searchUrl,
        get: getUrl,
        add: addUrl,
        edit: editUrl,
        remove: removeUrl,
      };
    },
    searchSection() {
      return {
        searchOnInit: true,
        rows: [
          [
            {
              name: "name",
              text: "Name",
              type: InputType.text,
              columnClass: "col-12 col-md-3",
            },
            {
              type: InputType.offset,
            },
            {
              type: InputType.offset,
            },
          ],
        ],
      };
    },
    permission() {
      return {
        add: [UserRole.ADMIN, UserRole.MENTOR, UserRole.SUPER_USER_UNIVERSITY],
        edit: [UserRole.ADMIN, UserRole.MENTOR, UserRole.SUPER_USER_UNIVERSITY],
        remove: [],
      };
    },
    tableSection() {
      return {
        columns: [
          {
            name: "name",
            text: "Name",
          },
          {
            name: "email",
            text: "Email",
          },
          {
            name: "tel",
            text: "เบอร์โทร",
          },
        ],
      };
    },
    formSection() {
      return {
        size: DialogSize.fullScreen,
        rows: [
          [
            {
              name: "name",
              text: "ชื่อ",
              type: InputType.text,
              rule: {
                required: true,
                max: 50,
              },
              columnClass: "col-12 col-md-3",
            },
            {
              name: "email",
              text: "Email",
              type: InputType.text,
              rule: {
                email: true,
                max: 40,
              },
              columnClass: "col-12 col-md-3",
            },
            {
              name: "tel",
              text: "เบอร์โทร",
              type: InputType.text,
              rule: {
                max: 22,
              },
              columnClass: "col-12 col-md-3",
            },
          ],
        ],
      };
    },
  },
};
</script>
